import { createRouter, createWebHistory } from "vue-router";

import NotFound from "./pages/NotFound.vue";
import PwdReset from "./pages/PwdReset.vue";
import UserAuth from "./pages/auth/UserAuth.vue";
import store from "./store/index.js";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/:notFound(.*)" },
    { path: "/resetPwd", query: { token: '*' }, component: UserAuth/*, meta: { requiresAuth: true }*/  },
    { path: "/pwdReset", component: PwdReset },
    { path: "/:notFound(.*)", component: NotFound }
  ]
});

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/:notFound(.*)");
  } else {
    next();
  }
});

export default router;
