<template>
  <base-card>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <h2>Password reset</h2>
    <p>
      Your password has been successfully reset.
    </p>
  </base-card>
</template>

<script>
export default {
  data() {
    return {
      error: null,
    };
  },
  methods: {
    handleError() {
      this.error = null;
    },
  },
};
</script>