<template>
  <div id="pwd-reset">
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog :show="isLoading" title="Authenticating . . ." fixed
      ><base-spinner></base-spinner
    ></base-dialog>
    <duo-card class="card-wrapper">
      <div class="side">
        <h2>Choose a new 🔑</h2>
        <b class="side-info__heading">Must contain . . .</b><br />
        <p
          class="side-info"
          :class="{ invalid: pwdValidity === 'invalid' }"
        >
          🔠 1 upper case letter<br />
          🔤 1 lower case letter<br />
          1️⃣ 1 number<br />
          *️⃣ 1 special character<br />
          🆙 8 characters in total<br />
          🔣 24 characters max
        </p>
        <p class="side-info invalid" v-if="!formIsValid">
          check your input
        </p>
      </div>
      <form @submit.prevent="submitForm">
        <div
          class="form-control"
          :class="{ invalid: pwdValidity === 'invalid' }"
        >
          <label for="password">New password</label>
          <div class="icon-bind">
            <input
              :type="pwdType"
              id="password"
              v-model.trim="password"
              @keyup="validatePwd, validateConfirmPwd"
            /><i
              id="pass-status"
              :class="pwdType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"
              aria-hidden="true"
              @click="viewPwd"
            ></i>
          </div>
        </div>

        <div
          class="form-control"
          :class="{ invalid: cPwdValidity === 'invalid' }"
        >
          <label for="cPassword">Confirm password</label>
          <div class="icon-bind">
            <input
              :type="cPwdType"
              id="cPassword"
              v-model.trim="cPassword"
              @keyup="validateConfirmPwd"
            /><i
              id="pass-status"
              :class="cPwdType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"
              aria-hidden="true"
              @click="viewCpwd"
            ></i>
          </div>
        </div>

        <base-button>Reset</base-button>
      </form>
    </duo-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      cPassword: "",
      pwdValidity: "pending",
      cPwdValidity: "pending",
      pwdType: "password",
      cPwdType: "password",
      passStatus: "",
      formIsValid: true,
      isLoading: false,
      error: null,
      token: this.$route.query.token,
      re: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,24}).*$/g,
    };
  },
  // async created() {
  //   try {
  //     await this.$store.dispatch("validateToken", this.token);
  //     // if(Success)
  //     this.$router.replace("/resetPwd");
  //   } catch (err) {
  //     this.error =
  //       err.message +
  //       " Failed to authenticate. try again later or request a new link.";
  //     throw error;
  //   }
  // },
  computed: {
    validatePwd() {
      if (this.re.test(this.password)) {
        this.pwdValidity = "valid";
      } else if (!this.re.test(this.password)) {
        this.pwdValidity = "invalid";
      }
    },
    validateConfirmPwd() {
      if (this.password === this.cPassword) {
        this.cPwdValidity = "valid";
        this.formIsValid = true;
      } else if (this.password !== this.cPassword) {
        this.cPwdValidity = "invalid";
      }
    },
  },
  methods: {
    viewPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "text";
      } else {
        this.pwdType = "password";
      }
    },
    viewCpwd() {
      if (this.cPwdType === "password") {
        this.cPwdType = "text";
      } else {
        this.cPwdType = "password";
      }
    },
    async submitForm() {
      this.formIsValid = true;

      if (!this.re.test(this.password) && !this.re.test(this.cPassword)) {
        this.formIsValid = false;
        return;
      }

      if (this.password === "" || this.password !== this.cPassword) {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        password: this.password,
        token: this.token,
      };
      try {
        await this.$store.dispatch("resetPwd", actionPayload);
        //   if(Success)
        //   => display password resetted / thank you page?
        this.$router.replace("/pwdReset");
      } catch (err) {
        this.error =
          err.message ||
          " Failed to authenticate. try again later or request a new link.";
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped lang="scss">
#pwd-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 270px;
  width: 90%;

  @media only screen and (min-width: 640px) {
    flex-direction: row;
    min-width: 40rem;
  }
}

.side {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgb(208, 176, 222);
  padding: 15px 30px;
  border-radius: 3px;
  min-height: 21rem;
  width: 100%;

  @media only screen and (min-width: 640px) {
    max-width: 270px;
  }

  h2 {
    color: #660066;
    text-shadow: 2px 2px whitesmoke;
  }
}

.side-info {
  background: rgb(109, 72, 131);
  padding: 15px;
  border-radius: 3px;
  color: white;
  max-width: 420px;
  margin-top: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

  &__heading {
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
    color: white;
    text-shadow: 1px 1px rgb(130, 77, 160);
  }

  
}

form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media only screen and (min-width: 640px) {
    align-self: center;
  }
}

.form-control {
  margin: 0.5rem 0;
  max-width: 450px;
  text-align: left;
}

.form-control.invalid input {
  border: 1px solid red;
}

.form-control.invalid label {
  color: red;
}

.side-info.invalid {
  background: rgb(209, 49, 102);
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  position: relative;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.3rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

input:focus,
textarea:focus {
  border-color: #660066;
  background-color: #faf6ff;
  outline: none;
}

.icon-bind {
  display: flex;
  align-items: center;
}

.fa {
  display: inline-block;
  color: #4d4a4a;
  font-size: 16px;
  padding: 7.5px;
  background: rgb(210, 210, 210);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>