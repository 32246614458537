<template>
  <base-card>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <h2>Page not found</h2>
    <p>
      The requested page could not be found - maybe check back later or request a new link.
    </p>
  </base-card>
</template>

<script>
export default {
  data() {
    return {
      error: null,
    };
  },
  methods: {
    handleError() {
      this.error = null;
    },
  },
};
</script>