<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 1px rgba(0,0,0,0.18), 
              0 4px 2px rgba(0,0,0,0.18), 
              0 8px 4px rgba(0,0,0,0.18), 
              0 16px 8px rgba(0,0,0,0.18),
              0 32px 16px rgba(0,0,0,0.18);
  margin: 2rem auto;
  max-width: 40rem;
  min-height: 24rem;
}
</style>
