export default {
  state() {
    return {
      token: null,
    };
  },
  actions: {
    async validateToken(_, token) {
      // User receives email and clicks link:
      const url = `https://dilo-api.de/CheckIsValid?token=${token}`;
      const response = await fetch(url);
      const responseData = await response.json();
      
      if (!response.ok) {
        const error = new Error(responseData.message);
        throw error;
      }
    },
    async resetPwd(context, payload) {
      return context.dispatch("setUserPwd", {
        ...payload,
      });
    },
    async setUserPwd(_, payload) {

      const url = `https://dilo-api.de/ChangePassword`;

      const saltArray = crypto.getRandomValues(new Uint8Array(16));
      const saltHex = saltArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
      const password = payload.password;
      const pwdBuffer = new TextEncoder('utf-8').encode(password);
      const hashBuffer = await window.crypto.subtle.digest('SHA-512', pwdBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          token: payload.token,
          password: `${hashHex}-${saltHex}`
        })
      });

      const responseData = await response.json();
      if (!response.ok) {
        const error = new Error(
          responseData.message + " Failed to authenticate."
        );
        throw error;
      }
    }
  },
};
